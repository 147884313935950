var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c(
          "card",
          [
            _c(
              "h4",
              {
                staticClass: "card-title",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_vm._v("\n        Stacked Form\n      ")]
            ),
            _c("fg-input", {
              attrs: { placeholder: "Enter email", label: "Email address" },
            }),
            _c("fg-input", {
              attrs: {
                placeholder: "Password",
                label: "Password",
                type: "password",
              },
            }),
            _c("p-checkbox", [_vm._v("Subscribe to newsletter")]),
            _c("p-button", { attrs: { type: "info", round: "" } }, [
              _vm._v("Submit"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-md-6" },
      [
        _c("card", [
          _c("form", { staticClass: "form-horizontal" }, [
            _c(
              "h4",
              {
                staticClass: "card-title",
                attrs: { slot: "header" },
                slot: "header",
              },
              [_vm._v("\n          Horizontal Form\n        ")]
            ),
            _c("div", { staticClass: "row" }, [
              _c("label", { staticClass: "col-md-3 col-form-label" }, [
                _vm._v("Username"),
              ]),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [_c("fg-input", { attrs: { placeholder: "Username" } })],
                1
              ),
              _c("label", { staticClass: "col-md-3 col-form-label" }, [
                _vm._v("Email"),
              ]),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [_c("fg-input", { attrs: { placeholder: "Email" } })],
                1
              ),
              _c("label", { staticClass: "col-md-3 col-form-label" }, [
                _vm._v("Password"),
              ]),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [_c("fg-input", { attrs: { placeholder: "Password" } })],
                1
              ),
              _c("label", { staticClass: "col-md-3 col-form-label" }),
              _c(
                "div",
                { staticClass: "col-md-9" },
                [
                  _c(
                    "fg-input",
                    { attrs: { placeholder: "Password" } },
                    [_c("p-checkbox", [_vm._v("Remember me")])],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "row", attrs: { slot: "footer" }, slot: "footer" },
              [
                _c("label", { staticClass: "col-md-3" }),
                _c(
                  "div",
                  { staticClass: "col-md-9" },
                  [
                    _c("p-button", { attrs: { type: "info", round: "" } }, [
                      _vm._v("\n              Sign in\n            "),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "col-md-12" }, [
      _c("div", { staticClass: "card" }, [
        _vm._m(0),
        _c("div", { staticClass: "card-body" }, [
          _c(
            "form",
            {
              staticClass: "form-horizontal",
              attrs: { method: "get", action: "/" },
            },
            [
              _vm._m(1),
              _vm._m(2),
              _vm._m(3),
              _vm._m(4),
              _vm._m(5),
              _c("fieldset", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("Checkboxes and radios"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _c(
                        "p-checkbox",
                        {
                          model: {
                            value: _vm.checkboxes.first,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "first", $$v)
                            },
                            expression: "checkboxes.first",
                          },
                        },
                        [_vm._v("First checkbox")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          model: {
                            value: _vm.checkboxes.second,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "second", $$v)
                            },
                            expression: "checkboxes.second",
                          },
                        },
                        [_vm._v("Second checkbox")]
                      ),
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radios.radio1,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio1", $$v)
                            },
                            expression: "radios.radio1",
                          },
                        },
                        [_vm._v("First Radio")]
                      ),
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.radios.radio1,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio1", $$v)
                            },
                            expression: "radios.radio1",
                          },
                        },
                        [_vm._v("Second Radio")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("fieldset", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("Inline checkboxes"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-10" },
                    [
                      _c(
                        "p-checkbox",
                        {
                          attrs: { inline: true },
                          model: {
                            value: _vm.checkboxes.a,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "a", $$v)
                            },
                            expression: "checkboxes.a",
                          },
                        },
                        [_vm._v("a")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          attrs: { inline: true },
                          model: {
                            value: _vm.checkboxes.b,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "b", $$v)
                            },
                            expression: "checkboxes.b",
                          },
                        },
                        [_vm._v("b")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          attrs: { inline: true },
                          model: {
                            value: _vm.checkboxes.c,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "c", $$v)
                            },
                            expression: "checkboxes.c",
                          },
                        },
                        [_vm._v("c")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("fieldset", [
                _c("legend", [_vm._v("Input Variants")]),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("Custom Checkboxes & radios"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-4 col-sm-offset-1" },
                    [
                      _c(
                        "p-checkbox",
                        {
                          model: {
                            value: _vm.checkboxes.unchecked,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "unchecked", $$v)
                            },
                            expression: "checkboxes.unchecked",
                          },
                        },
                        [_vm._v("Unchecked")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          model: {
                            value: _vm.checkboxes.checked,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "checked", $$v)
                            },
                            expression: "checkboxes.checked",
                          },
                        },
                        [_vm._v("Unchecked")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.checkboxes.disabledUnchecked,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "disabledUnchecked", $$v)
                            },
                            expression: "checkboxes.disabledUnchecked",
                          },
                        },
                        [_vm._v("Disabled unchecked")]
                      ),
                      _c(
                        "p-checkbox",
                        {
                          attrs: { disabled: true },
                          model: {
                            value: _vm.checkboxes.disabledChecked,
                            callback: function ($$v) {
                              _vm.$set(_vm.checkboxes, "disabledChecked", $$v)
                            },
                            expression: "checkboxes.disabledChecked",
                          },
                        },
                        [_vm._v("Disabled checked")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-5" },
                    [
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "1" },
                          model: {
                            value: _vm.radios.radio2,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio2", $$v)
                            },
                            expression: "radios.radio2",
                          },
                        },
                        [_vm._v("Radio is off")]
                      ),
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "2" },
                          model: {
                            value: _vm.radios.radio2,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio2", $$v)
                            },
                            expression: "radios.radio2",
                          },
                        },
                        [_vm._v("Radio is on")]
                      ),
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "1", disabled: true },
                          model: {
                            value: _vm.radios.radio2,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio2", $$v)
                            },
                            expression: "radios.radio2",
                          },
                        },
                        [_vm._v("Disabled radio is off")]
                      ),
                      _c(
                        "p-radio",
                        {
                          attrs: { label: "2", disabled: true },
                          model: {
                            value: _vm.radios.radio2,
                            callback: function ($$v) {
                              _vm.$set(_vm.radios, "radio2", $$v)
                            },
                            expression: "radios.radio2",
                          },
                        },
                        [_vm._v("Disabled radio is on")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._m(6),
              _c("fieldset", [
                _c(
                  "label",
                  { staticClass: "col-sm-2 control-label text-danger" },
                  [_vm._v("Input with error")]
                ),
                _c(
                  "div",
                  { staticClass: "col-sm-10" },
                  [
                    _c("fg-input", {
                      attrs: { error: "Input error", value: "Error" },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._m(7),
              _c("fieldset", [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "col-sm-2 control-label" }, [
                    _vm._v("Input groups"),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c(
                        "fg-input",
                        { attrs: { placeholder: "Username" } },
                        [_c("template", { slot: "addonLeft" }, [_vm._v("@")])],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-sm-3" },
                    [
                      _c(
                        "fg-input",
                        [
                          _c("template", { slot: "addonRight" }, [
                            _vm._v(".00"),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._m(8),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "card-header" }, [
      _c("h4", { staticClass: "card-title" }, [_vm._v("Form Elements")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("With help"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", { staticClass: "form-control", attrs: { type: "text" } }),
          _c("span", { staticClass: "help-block" }, [
            _vm._v("A block of help text that breaks onto a new line."),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Password"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "password", name: "password" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Placeholder"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "text", placeholder: "placeholder" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Disabled"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: {
              type: "text",
              placeholder: "Disabled input here...",
              disabled: "",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("static/ control"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("p", { staticClass: "form-control-static/" }, [
            _vm._v("hello@creative-tim.com"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group has-success" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Input with success"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("input", {
            staticClass: "form-control",
            attrs: { type: "text", value: "Success" },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group column-sizing" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Column sizing"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "text", placeholder: ".col-md-3" },
              }),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "text", placeholder: ".col-md-4" },
              }),
            ]),
            _c("div", { staticClass: "col-md-5" }, [
              _c("input", {
                staticClass: "form-control",
                attrs: { type: "text", placeholder: ".col-md-5" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("fieldset", [
      _c("div", { staticClass: "form-group" }, [
        _c("label", { staticClass: "col-sm-2 control-label" }, [
          _vm._v("Textarea"),
        ]),
        _c("div", { staticClass: "col-sm-10" }, [
          _c("textarea", {
            staticClass: "form-control",
            attrs: { placeholder: "Here can be your nice text", rows: "3" },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }